import Web3 from 'web3';

const GasStationUrl = process.env.REACT_APP_POLYGON_GAS_STATION;

const historicalBlocks = 4;

const formatFeeHistory = (result, includePending) => {
    try {
        let blockNum = Number(result.oldestBlock);
        let index = 0;
        const blocks = [];
        while (blockNum < Number(result.oldestBlock) + historicalBlocks) {
            blocks.push({
                number: blockNum,
                baseFeePerGas: Number(result.baseFeePerGas[index]),
                gasUsedRatio: Number(result.gasUsedRatio[index]),
                priorityFeePerGas: result.reward[index].map(x => Number(x)),
            });
            blockNum += 1;
            index += 1;
        }
        if (includePending) {
            blocks.push({
                number: "pending",
                baseFeePerGas: Number(result.baseFeePerGas[historicalBlocks]),
                gasUsedRatio: NaN,
                priorityFeePerGas: [],
            });
        }
        return blocks;
    } catch (err) {
        console.log(err);
    }
}

const fetchMaxPriorityFees = async () => {
    try {
        let res = await fetch(GasStationUrl);
        let resJson;
        if (res.status === 200) {
            resJson = await res.json();
        } else {
            throw new Error("Not 200 response");
        }
        const maxPriorityFee = resJson?.standard?.maxPriorityFee || 0;
        return maxPriorityFee;
    } catch (err) {
        console.log(err);
        return 0;
    }
}

export const estimatePriorityFee = async (web3) => {
    try {
        let value;
        const feeHistories = await web3.eth.getFeeHistory(historicalBlocks, "pending", [10]);

        if (feeHistories.reward.length >= historicalBlocks) {
            const blocks = formatFeeHistory(feeHistories, false);
            const firstPercentilePriorityFees = blocks.map(b => b.priorityFeePerGas[0]);
            const sum = firstPercentilePriorityFees.reduce((a, v) => a + v);
            value = Math.ceil(sum / firstPercentilePriorityFees.length);
        } else {
            value = await fetchMaxPriorityFees();            
            // Value from fetchMaxPriorityFees comes in Gwei. So converting into Wei.
            value = web3.utils.toWei(Number((value).toFixed(9)).toString(), 'gwei');
        }
        return value;
    } catch(e) {
        console.log(e);
        throw(e)
    }
}

export const createContract = async (DEPLOYMENT_CONTEXT, CONTRACT_NAME, PROVIDER=null) => {
    try {
        let web3 = new Web3(window.ethereum);
        const contract = new web3.eth.Contract(DEPLOYMENT_CONTEXT.contracts[CONTRACT_NAME].abi, DEPLOYMENT_CONTEXT.contracts[CONTRACT_NAME].address);
        if (PROVIDER) {
            contract.setProvider(PROVIDER);
        }
        return contract;
    } catch (e) {
        console.log(e);
    }
};
