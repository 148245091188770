import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './InventoryItem.module.scss';
import { Button } from 'react-bootstrap';
import BridgeIcon from '../../assets/icons/icon-bridge.svg';
import SwapIcon from '../../assets/icons/icon-swap.svg';
import disableTransfer from '../../assets/icons/transfer_horiz_24px_blue.svg'
import { useHistory } from 'react-router-dom';
import { TokenContext, TYPES, ABIContext } from '../../components/Context';
import TransferCardModal from '../TrasnferCardModal/TransferCardModal';
import { GeneralModal } from '../GeneralModal';
import { fetchCards, getCardsInOrder, fetchCardDetails } from '../../services/cardService';
import { Web3Context } from '../../web3';
import { PolygonNetworkInfo } from '../../services/networkServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { toBN } from 'web3-utils';
import { estimatePriorityFee } from '../../services/contractService';

const BACKEND_API = process.env.REACT_APP_BACKEND_API;
const CHAIN_ID = parseInt(process.env.REACT_APP_POLYGON_CHAIN_ID);
const CARD_NFTS_ENABLED = process.env.REACT_APP_CARD_NFTS_ENABLED;
const BRIDGING_ENABLED = process.env.REACT_APP_BRIDGING_ENABLED;

const polyscanTxUrls = Object.freeze({
    137: "https://polygonscan.com/tx/",
    80001: "https://mumbai.polygonscan.com/tx/"
});

const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function InventoryItem(props) {
    const { type, image, text, amount, contractAddress, name, operatorContract, operatorContractName, tokenContract, tokenContractName } = props.content || {};
    const { fetchMaticChests, web3, selectedAddress, selectedAccount } = props;
    const [t] = useTranslation();
    const history = useHistory();
    const [state, dispatch] = useContext(TokenContext);
    const [abiConfig] = useContext(ABIContext);
    const { inventory: { inventoryContract, erc20Abi }, polygonProvider, polygonDeploymentContext } = abiConfig;
    const [openChestFlag, setOpenChestFlag] = useState(false);
    const [approveFlag, setApproveFlag] = useState(false);
    const [transferModal, setTransferModal] = useState(false);
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [polyscanTxUrl, setPolyscanTxUrl] = useState('');
    const { switchNetwork } = useContext(Web3Context);
    const [openChestCount, setOpenChestCount] = useState(1);

    const closeMessageModal = () => {
        setShowMessageModal(false);
        setPolyscanTxUrl('');
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(contractAddress)
    }

    const waitForReceipt = (hash, successCb, failedCb) => {
        web3.eth.getTransactionReceipt(hash, function (err, receipt) {
            if (err) {
                console.log(err);
            }

            if (receipt && receipt.blockHash !== undefined) {
                // Transaction went through
                if (receipt.status === true) {
                    successCb();
                } else {
                    failedCb();
                }
            } else {
                // Try again in 1 second
                window.setTimeout(function () {
                    waitForReceipt(hash, successCb, failedCb);
                }, 2000);
            }
        });
    }

    const approveTokenToUser = async (allowance) => {
        try {
            let approvalAddress = polygonDeploymentContext.contracts[operatorContractName].address;
            let contract = tokenContract;
    
            let gasPrice = await web3.eth.getGasPrice();
            let gasPriceBN = toBN(gasPrice);
            let gasEstimate = await contract.methods
                .approve(approvalAddress, allowance)
                .estimateGas({from: selectedAccount});
            let finalGasPrice = gasPriceBN.add(toBN(parseInt(0.1 * gasPriceBN))).toString();
            let estimatedPriorityFee = await estimatePriorityFee(web3);
            estimatedPriorityFee = parseInt(estimatedPriorityFee + 0.2 * estimatedPriorityFee);
            let finalMaxFeePerGas = toBN(estimatedPriorityFee).add(toBN(parseInt(0.2 * gasPriceBN))).toString();

            let receipt = await contract.methods.approve(approvalAddress, allowance).send({
                from: selectedAccount,
                gasLimit: gasEstimate,
                gasPrice: finalGasPrice,
                maxFeePerGas: finalMaxFeePerGas,
                maxPriorityFeePerGas: estimatedPriorityFee
            })
                .on('transactionHash', (txHash) => {
                    console.log(`Transaction hash obtained: ${txHash}`);
                })
                .on('receipt', (receipt) => {
                    console.log(receipt);
                });
    
            if (receipt.status === true) {
                return true;
            }
        } catch (err) {
            if (err.code !== 4001) {
                console.log(err);
                return err;
            } else {
                return null;
            }
        }

        return false;
    }

    const onTransferTokenToUser = async (transferAddress, transferAmount, setLoading, setTransfer, setTxHashLink, setSignTransfer, setShowMessageModal, setErrorMsg) => {
        if (transferAddress != "" && transferAmount != 0 && tokenContract) {
            try {
                setLoading(true);
                setSignTransfer(true);

                let switchNetworkResponse = await switchNetwork(PolygonNetworkInfo);
                if (!switchNetworkResponse) {
                    setLoading(false);
                    setSignTransfer(false);
                    return;
                }

                let gasPrice = await web3.eth.getGasPrice();
                let gasPriceBN = toBN(gasPrice);
                let gasEstimate = await tokenContract.methods
                    .transfer(transferAddress, transferAmount)
                    .estimateGas({from: selectedAccount});
                let finalGasPrice = gasPriceBN.add(toBN(parseInt(0.1 * gasPriceBN))).toString();
                let estimatedPriorityFee = await estimatePriorityFee(web3);
                estimatedPriorityFee = parseInt(estimatedPriorityFee + 0.2 * estimatedPriorityFee);
                let finalMaxFeePerGas = toBN(estimatedPriorityFee).add(toBN(parseInt(0.2 * gasPriceBN))).toString();

                let receipt = await tokenContract.methods.transfer(transferAddress, transferAmount).send({
                    from: selectedAccount,
                    gasLimit: gasEstimate,
                    gasPrice: finalGasPrice,
                    maxFeePerGas: finalMaxFeePerGas,
                    maxPriorityFeePerGas: estimatedPriorityFee
                })
                    .on('transactionHash', (txHash) => {
                        console.log(`Transaction hash obtained: ${txHash}`);
                        setTxHashLink(txHash);
                    })
                    .on('receipt', (receipt) => {
                        console.log(receipt);
                    })
                    .on('error', err => {
                        if (err.code !== 4001) {
                            console.log(err);
                            throw new Error("Chest transfer failed");
                        }
                    });

                if (receipt.status === true) {
                    setSignTransfer(false);
                    setTransfer(true);
                    setLoading(false);
                    fetchMaticChests(polygonProvider, abiConfig.polygonDeploymentContext.contracts, erc20Abi)
                    return true;
                }
            } catch (e) {
                console.log(e);
                setLoading(false);
                setSignTransfer(false);
                setTransfer(false);

                if (e.code !== 4001) {
                    setErrorMsg((prevState) => {
                        return { ...prevState, transferError: 'Please provide valid wallet address' }
                    });
                    setShowMessageModal(true);
                }
            }
        } else {
            console.log("Please enter the correct arguments");
        }
    }

    const handleBridgeClick = () => {
        dispatch({
            type: TYPES.SET_BRIDGE_CHEST,
            payload: {
                name,
                tokenContractName
            },
        });
        history.push('/bridge/chests');
    }

    const openAnimationPage = async () => {
        dispatch({
            type: TYPES.SET_OPEN_CHEST,
            payload: name,
        });
        setOpenChestFlag(false);

        const chestCount = {
            gold: 0,
            silver: 0,
            bronze: 0,
        };

        dispatch({
            type: TYPES.SET_CHEST_COUNT_MATIC,
            payload: chestCount,
        });

        if (inventoryContract) {
            let nftList = await fetchCards(inventoryContract, selectedAccount);
            while (nftList.length == state.totalCardCount) {
                nftList = await fetchCards(inventoryContract, selectedAccount);
                await sleep(3000);
            }
            
            let orderedNFTList = await getCardsInOrder(inventoryContract, nftList, 'desc');
            orderedNFTList = orderedNFTList.slice(0, 3);
            const latestCardDetails = await fetchCardDetails(orderedNFTList);
            dispatch({
                type: TYPES.SET_LATEST_CARD_DETAILS,
                payload: {
                    latestCardDetails
                }
            });
            history.push('/open-chest');
        };

        props.setChestOpening(false);
    }

    const handleOpenClick = async () => {
        try {
            setOpenChestFlag(true);
    
            let switchNetworkResponse = await switchNetwork(PolygonNetworkInfo);
            if (!switchNetworkResponse) {
                setOpenChestFlag(false);
                return;
            }

            let userAddress = selectedAccount;
            let totalChestCountInWei = web3.utils.toWei(amount.toString(), 'ether');
            let inventoryAllowance = await tokenContract.methods.allowance(userAddress, polygonDeploymentContext.contracts[operatorContractName].address).call();
            inventoryAllowance = web3.utils.fromWei(inventoryAllowance, 'ether')
            
            let approval;
            if (parseInt(inventoryAllowance) < parseInt(openChestCount)) {
                setApproveFlag(true);
                approval = await approveTokenToUser(totalChestCountInWei.toString());
                setApproveFlag(false);
            } else {
                approval = true;
            }

            if (approval === true) {
                props.setChestOpening(true);
                let chestQuantity = openChestCount;

                const signatureResponse = await fetch(`${BACKEND_API}/signature`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        userAddress: selectedAddress,
                        chestOperatorAddress: polygonDeploymentContext.contracts[operatorContractName].address,
                        quantity: chestQuantity
                    })
                });
                let signature = await signatureResponse.json();
                if (!signature.signature) {
                    throw new Error('Made too many requests to the server.');
                }
                signature = signature.signature;

                let gasPrice = await web3.eth.getGasPrice();
                let gasPriceBN = toBN(gasPrice);
                let gasEstimate = await operatorContract.methods
                    .open(chestQuantity, signature)
                    .estimateGas({from: selectedAccount});
                let finalGasPrice = gasPriceBN.add(toBN(parseInt(0.1 * gasPriceBN))).toString();
                let estimatedPriorityFee = await estimatePriorityFee(web3);
                estimatedPriorityFee = parseInt(estimatedPriorityFee + 0.2 * estimatedPriorityFee);
                let finalMaxFeePerGas = toBN(estimatedPriorityFee).add(toBN(parseInt(0.2 * gasPriceBN))).toString();

                await operatorContract.methods.open(chestQuantity, signature).send({
                    from: selectedAccount,
                    gasLimit: gasEstimate,
                    gasPrice: finalGasPrice,
                    maxFeePerGas: finalMaxFeePerGas,
                    maxPriorityFeePerGas: estimatedPriorityFee
                })
                    .on('transactionHash', (txHash) => {
                        console.log(`Transaction hash obtained: ${txHash}`);
                        setPolyscanTxUrl(`${polyscanTxUrls[CHAIN_ID]}${txHash}`);

                        waitForReceipt(txHash,
                            () => {
                                openAnimationPage();
                            },
                            () => {
                                console.log("Chest opening failed");
                            });
                    })
                    .on('receipt', (receipt) => {
                        console.log(receipt);
                    })
                    .on('error', err => {
                        if (err.code !== 4001) {
                            console.log(err);
                        }
                    });
            } else if (approval === null) {
                setOpenChestFlag(false);
                return;
            } else {
                throw new Error("Approval Failed!");
            }
        } catch (err) {
            if (err.code !== 4001) {
                console.log(err);
                setShowMessageModal(true);
                setPolyscanTxUrl('');
            }
        } finally {
            setOpenChestFlag(false);
            props.setChestOpening(false);
        }
    }

    const handleOpenCount = (countIncrement) => {
        let value = openChestCount + countIncrement;
        if (value >= 1 && value <= 3 && value <= amount) {
            setOpenChestCount(value);
        }
    }

    return (
        <>
            <div className={`${styles.chest_item_container} ${type == 'polygon' && styles.polygon_item_container}`}>
                <div className={styles.image_container}>
                    <img src={image} alt="chest" />
                </div>
                <div className={styles.items_container}>
                    <div className={styles.items}>
                        <div className={styles.title}>{text}</div>
                        <div className={styles.amount}>{amount}</div>
                    </div>
                </div>
                <div className={styles.btn_container}>
                    {type === "polygon" && (
                        <Button disabled={amount < 1 ? true : false} variant="cdh-general-blue-dbrown" className={`${styles.secondary_btn} ${styles.transferBtn}`} onClick={() => setTransferModal(true)}>
                            <div className={styles.btn_icon}>
                                <img src={amount < 1 ? disableTransfer : SwapIcon} alt="transfer" />
                            </div>
                            <div className={styles.btn_text}>{t('TRANSFER')}</div>
                        </Button>
                    )}
                    {
                        type === 'ethereum' ?
                            BRIDGING_ENABLED === "true" ?
                                <Button disabled={amount < 1 ? true : false} variant="cdh-general-blue-dbrown" className={styles.primary_btn} onClick={handleBridgeClick}>
                                    <div className={styles.btn_icon}>
                                        <img src={BridgeIcon} alt="bridge" />
                                    </div>
                                    <div className={styles.btn_text}>{t('BRIDGE_POLYGON_BTN')}</div>
                                </Button> :
                                ""
                            : CARD_NFTS_ENABLED === "true" ?
                                <Button disabled={(amount < 1) || approveFlag || openChestFlag || !operatorContract || !tokenContract} variant="cdh-general-blue-dbrown" className={styles.primary_btn} onClick={handleOpenClick}>
                                    <div className={styles.btn_text}>{approveFlag ? t('Processing') : openChestFlag ? t('OPENING_CHEST') : t('OPEN')}</div>
                                </Button> :
                                ""
                    }
                </div>
                {type === 'polygon' &&
                    <div className={styles.open_count_container}>
                        <FontAwesomeIcon
                            size={'1x'}
                            icon={faAngleUp}
                            className={styles.arrow_btn}
                            onClick={() => handleOpenCount(1)}
                        />
                        <div className={styles.count_box}>{openChestCount}</div>
                        <FontAwesomeIcon
                            size={'1x'}
                            icon={faAngleDown}
                            className={styles.arrow_btn}
                            onClick={() => handleOpenCount(-1)}
                        />
                    </div>
                }
                
                {transferModal && (
                    <TransferCardModal
                        isCard={false}
                        isChest={true}
                        openModal={transferModal}
                        setOpenModal={setTransferModal}
                        itemImg={image}
                        chestType={text}
                        onTransferTokenToUser={onTransferTokenToUser}
                        web3={web3}
                        maxAmount={amount}
                    />
                )}
                <GeneralModal content={{
                    show: showMessageModal,
                    showSetter: setShowMessageModal,
                    title: t('ERROR'),
                    description: t('Error While Opening the Chest. Please try again in sometime!'),
                    buttonText: t('OK'),
                    buttonCallback: closeMessageModal,
                    polygonscanAddress: polyscanTxUrl
                }} />
            </div>
        </>
    );
}
