import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './SocialMediaLinks.module.scss';
import discordLogo from '../../assets/icons/icon-discord.svg';
import mediumLogo from '../../assets/icons/icon-medium-white.svg';
import telegramLogo from '../../assets/icons/icon-telegram.svg';
import twitterLogo from '../../assets/icons/icon-twitter.svg';
import weiboLogo from '../../assets/icons/icon-weibo-color.svg';
import wechatLogo from '../../assets/icons/icon-wechat-color.svg';
import facebookLogo from '../../assets/icons/icon-facebook-color.svg';
import instagramLogo from '../../assets/icons/icon-instagram-color.svg';
import fandomLogo from '../../assets/icons/icon-fandom.png';

const SocialMediaLinks = () => {
    const [t] = useTranslation();

    const socialsFirstColumn = {
        twitter: { link: 'https://twitter.com/TowerToken', logo: twitterLogo },
        discord: { link: 'https://discord.gg/MzKppSCKVu', logo: discordLogo },
        telegram: { link: 'https://t.me/TowerToken', logo: telegramLogo },
        medium: { link: 'https://medium.com/tower-token', logo: mediumLogo },
        fandom: { link: 'https://crazydefenseheroes.fandom.com/wiki/Crazy_Defense_Heroes_Wiki', logo: fandomLogo },
    };

    const socialsSecondColumn = {
        instagram: { link: 'https://www.instagram.com/crazydefenseheroes', logo: instagramLogo },
        facebook: { link: 'https://www.facebook.com/TowerTokenAB/', logo: facebookLogo },
        weibo: { link: 'https://weibo.com/CrazyDefenseHeroes', logo: weiboLogo },
        wechat: { link: 'https://crazydefenseheroes.com/wechat-qr.jpg', logo: wechatLogo },
    };

    const renderLinks = (links) => {
        return Object.keys(links).map((sm, i) => {
            return (
                <div className={styles.link} key={i}>
                    <a href={links[sm].link} target="_blank" rel="noopener noreferrer">
                        <img src={links[sm].logo} alt="links-button" />
                    </a>
                </div>
            );
        });
    };

    return (
        <div className={styles.social_media_links}>
            <div className={styles.follow_us}>{t('FOLLOW_US_ON')}</div>
            <div className={styles.social_links}>
                <div className={styles.links_set}>
                    {renderLinks(socialsFirstColumn)}
                </div>
                <div className={styles.links_set}>
                    {renderLinks(socialsSecondColumn)}
                </div>
            </div>
        </div>
    );
}

export { SocialMediaLinks };