import React, { createContext, useEffect, useReducer } from 'react';
import Web3 from "web3";
import Web3Modal from "web3modal";
import TYPES from './Web3Types';
// import WalletConnect from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import { providers } from 'web3modal';


const Web3Context = createContext();

const initialState = {
    web3: null,
    provider: null,
    ready: false,
    selectedAccount: null,
    chainId: null,
    networkId: null,
}

const reducer = (state, action) => {
    switch (action.type) {
        case TYPES.WEB3_INIT:
            const {
                web3,
                provider,
                ready,
                selectedAccount,
                chainId,
                networkId
            } = action.payload;

            return {
                ...state,
                web3,
                provider,
                ready,
                selectedAccount,
                chainId,
                networkId
            };

        case TYPES.UPDATE_CHAIN_AND_NETWORK_ID:
            return {
                ...state,
                chainId: action.payload.chainId,
                networkId: action.payload.networkId,
            }

        case TYPES.UPDATE_CONNECTED_ADDRESS:
            return {
                ...state,
                selectedAccount: action.payload.selectedAccount
            }

        case TYPES.WEB3_CLEAR:
            return {
                ...state,
                web3: null,
                provider: null,
                ready: false,
                selectedAccount: null,
                chainId: null,
                networkId: null
            };

        default:
            return state;
    }
}

const RPC_URLS = {
    1: process.env.REACT_APP_ETHEREUM_PROVIDER_URL,
    4: process.env.REACT_APP_ETHEREUM_PROVIDER_URL,
    5: process.env.REACT_APP_ETHEREUM_PROVIDER_URL,
    137: process.env.REACT_APP_POLYGON_PROVIDER_URL,
    80001: process.env.REACT_APP_POLYGON_PROVIDER_URL,
    // Need to update env naming for binance provider
    56: process.env.REACT_APP_BINANCE_PROVIDER_URL,
    97: process.env.REACT_APP_BINANCE_PROVIDER_URL
}

const Web3Provider = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        if (web3Modal.cachedProvider) {
            connect();
        }
    }, [])

    const providerOptions = {
        "custom-metamask": {
            display: {
                logo: providers.METAMASK.logo,
                name: window?.ethereum?.isMetaMask ? 'MetaMask' : (window?.ethereum?.providers && window?.ethereum?.providers?.find((p) => p.isMetaMask) ? 'MetaMask' : 'Install MetaMask'),
                description: 'Connect using Metamask Wallet'
            },
            package: true,
            connector: async () => {
                // Case 1: There is no injected provider available
                // Resolution: Open MetaMask download in new tab
                if (window.ethereum == undefined) {
                    //console.log("No Injected Providers Available");
                    window.open("https://metamask.io/download/", "_blank");
                    return;
                }

                // Case 2: There are multiple providers available.
                // Resolution: Check if an injected provider is Metamask,
                //  if true, return the provider. If false, open MetaMask download
                //  in new tab.
                else if (window.ethereum.providers !== undefined) {
                    let providers = window.ethereum.providers;
                    let provider = providers.find((p) => p.isMetaMask && !p.isBraveWallet);
                    if (provider) {
                        //console.log("MetaMask provider located");
                        try {
                            await provider.request({ method: "eth_requestAccounts" });
                            return provider;
                        } catch (error) {
                            throw new Error("User Rejected");
                        }
                    } else {
                        //console.log("MetaMask not an available provider");
                        window.open("https://metamask.io/download/", "_blank");
                        return;
                    }
                }

                // Case 3: There is one injected provider available.
                // Resolution: If it is MetaMask, return the provider.
                //  Otherwise, open download in new tab.
                else if (window.ethereum.providers == undefined && window.ethereum.isMetaMask == true) {
                    //console.log("MetaMask is the single injected provider");
                    let provider = window.ethereum;
                    try {
                        await provider.request({ method: "eth_requestAccounts" });
                        return provider;
                    } catch (error) {
                        throw new Error("User Rejected");
                    }
                } else {
                    window.open("https://metamask.io/download/", "_blank");
                    return;
                }
            },
        },
        // walletconnect: {
        //     package: WalletConnect,
        //     options: {
        //         rpc: {
        //             1: process.env.REACT_APP_PROVIDER_URL,
        //             4: process.env.REACT_APP_PROVIDER_URL
        //         }
        //     }
        // },
        walletlink: {
            package: CoinbaseWalletSDK, // Required
            display: {
                description: 'Connect using Coinbase Wallet'
            },
            options: {
                appName: "TOWER - Crazy Defense Heroes", // Required
                rpc: "_", // Using "_" instead of blank "" string as 'rpc' is necessary for QR code scan option when coinbase wallet extension is not installed
            }
        },
    };

    const web3Modal = new Web3Modal({
        network: "mainnet", // optional
        cacheProvider: true, // optional
        providerOptions, // required
        disableInjectedProvider: true
    });

    const connect = async () => {
        try {
            const provider = await web3Modal.connect();
            const web3 = new Web3(provider);
            await subscribeProvider(web3, provider);
            await provider.enable();
            if (!window.ethereum) {
                window.ethereum = provider;
            }
            const accounts = await web3.eth.getAccounts();
            const selectedAccount = web3.utils.isAddress(accounts[0]) ? web3.utils.toChecksumAddress(accounts[0]) : accounts[0];
            const networkId = await web3.eth.net.getId();
            const chainId = await web3.eth.getChainId();

            // Some browsers are not provided jsonRpcUrl from the cb extension, so adding manually
            if (provider?.isCoinbaseWallet && provider.jsonRpcUrl === "_") {
                provider.jsonRpcUrl = RPC_URLS[chainId];
            }

            dispatch({
                type: TYPES.WEB3_INIT,
                payload: {
                    web3,
                    provider,
                    ready: true,
                    selectedAccount,
                    chainId,
                    networkId
                }
            });
        } catch (err) {
            console.log(err);
            web3Modal.clearCachedProvider();
        }
    };

    const disconnect = async () => {
        try {
            const { web3 } = state;
            if (web3 && web3.currentProvider && web3.currentProvider.close) {
                await web3.currentProvider.close();
            }
            web3Modal.clearCachedProvider();

            dispatch({
                type: TYPES.WEB3_CLEAR
            });
        } catch (err) {
            console.log(err);
        }
    };

    const subscribeProvider = async (web3, provider) => {
        try {
            if (!provider.on) {
                return;
            }

            provider.on("close", () => disconnect());
            provider.on("accountsChanged", async (accounts) => {
                // Web3Modal stores localstorage item to keep track of disconnected unit. It does not work properly, so using this little trick.
                let Web3ConnectCached = localStorage.getItem('WEB3_CONNECT_CACHED_PROVIDER');
                Web3ConnectCached = Web3ConnectCached?.replace(/["]+/g, '');
                if (Web3ConnectCached) {
                    let selectedAccount = accounts[0];
                    selectedAccount = web3.utils.toChecksumAddress(selectedAccount);

                    dispatch({
                        type: TYPES.UPDATE_CONNECTED_ADDRESS,
                        payload: {
                            ...state,
                            selectedAccount
                        }
                    });
                }
            });

            // let currentChainId = await web3.eth.getChainId();
            provider.on("chainChanged", async (chainId) => {
                // While using Coinbase wallet QR scan connect, chainChanged is repeateadly running
                // This check prevents the check
                // if (currentChainId === chainId) {
                //     return;
                // }

                const networkId = await web3.eth.net.getId();
                let Web3ConnectCached = localStorage.getItem('WEB3_CONNECT_CACHED_PROVIDER');
                Web3ConnectCached = Web3ConnectCached?.replace(/["]+/g, '');
                if (Web3ConnectCached) {
                    dispatch({
                        type: TYPES.UPDATE_CHAIN_AND_NETWORK_ID,
                        payload: {
                            chainId,
                            networkId,
                        }
                    });
                }
            });

            // Updating networkId when subscription detects chainId change. Also using Coinbase wallet extension does not detect newtworkId change.
            // Todo: Separate dispatch instead of connect().
            // provider.on("networkChanged", async (networkId) => {
            //     let Web3ConnectCached = localStorage.getItem('WEB3_CONNECT_CACHED_PROVIDER');
            //     Web3ConnectCached = Web3ConnectCached?.replace(/["]+/g, '');
            //     if (Web3ConnectCached) {
            //         connect();
            //     }
            // });
        } catch (err) {
            console.log(err);
        }
    };

    const switchNetwork = async (networkInfo) => {
        let provider;
        if (state.provider) {
            provider = state.provider;
        } else {
            provider = window.ethereum ? (window.ethereum.providers ? window.ethereum.providers[0] : window.ethereum) : false;
        }

        if (provider) {
            try {
                await provider.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: networkInfo.chainId }],
                });
                return true;
            } catch (error) {
                console.log(error);
                // This error code indicates that the chain has not been added to MetaMask.
                if (error.code === 4902 || error?.data?.originalError?.code === 4902) {
                    try {
                        await provider.request({
                            method: 'wallet_addEthereumChain',
                            params: [
                                networkInfo
                            ],
                        });
                    } catch (addError) {
                        // handle "add" error
                        console.log(addError);
                    }
                }
                return false;
            }
        }
        // Non-DApp Browsers
        else {
            alert('You have to connect wallet first!');
        }
    };

    const watchAsset = async (tokenInformation) => {
        let provider;
        if (state.provider) {
            provider = state.provider;
        } else {
            provider = window.ethereum ? (window.ethereum.providers ? window.ethereum.providers[0] : window.ethereum) : false;
        }
        if (provider) {
            await provider.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20',
                    options: tokenInformation
                },
            })
            .then((success) => {
                if (!success) {
                    throw new Error('Something went wrong while adding token.');
                }
            }).catch(console.error);
        }
        // Non-DApp Browsers
        else {
            alert('You have to connect wallet first!');
        }
    };

    // Request wallet permission, also allows to Switch wallet.
    const requestWalletPermissions = async () => {
        try {
            if (!state?.provider) {
                connect();
            }

            const response = await state.provider.request({
                method: 'wallet_requestPermissions',
                params: [{
                  'eth_accounts': {},
                }]
            })
            console.log(response);
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    };

    return (
        <Web3Context.Provider value={{
            ...state,
            connect,
            disconnect,
            switchNetwork,
            watchAsset,
            requestWalletPermissions,
        }}>
            {props.children}
        </Web3Context.Provider>
    )
}

export { Web3Context, Web3Provider };
