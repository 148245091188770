import React from 'react';
import { useTranslation } from 'react-i18next'; 
import { Modal } from 'react-bootstrap';
import styles from './AddTowerModal.module.scss';
import SwapIcon from '../../assets/icons/swap-icon.png';
import { useHistory } from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import iconOnMetaMin from '../../assets/icons/onMetaLogoMin.svg';
import iconOnMeta from '../../assets/icons/onMetaLogo.svg';
import iconSwapMatic from '../../assets/icons/swap-matic.svg';

const AddTowerModal = (props) => {
    const [t] = useTranslation();
    const history = useHistory()
    const { showAddTowerModal, setShowAddTowerModal } = props;

    const handleClose = () => setShowAddTowerModal(false);

    const goToEarnTower = () => {
        handleClose();
        history.push('/earn-tower');
    }

    const goToSwapTower = (type) => {
        handleClose();
        history.push(`/buy-tower/matic/${type}`);
    }
    
    return (
        (showAddTowerModal)
        ?
            (
                <Modal show={showAddTowerModal} className={styles.addtower_modal} onHide={handleClose} centered>
                    <div className={styles.close_btn} onClick={() => setShowAddTowerModal(false)}><FontAwesomeIcon icon={faTimes}/></div>
                    <Modal.Header className={styles.addtower_modal_header}>
                        <Modal.Title>
                            <div className={styles.addtower_modal_title}>Get TOWER</div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={styles.addtower_modal_container}>
                        <div className={styles.buy_tower_section} onClick={() => goToSwapTower("buy")}>
                            <div className={styles.buy_tower_icon}>
                            <img className="logo" src={iconOnMetaMin} />
                            </div>
                            <div className={styles.buy_tower_text}>
                                <div className={styles.buy_tower_text_title}>Buy $TOWER</div>
                                <div className={styles.buy_tower_text_subtitle}>Buy $TOWER with <span><img src={iconOnMeta}/></span> </div>
                            </div>
                        </div>
                        <div className={styles.swap_matic_section} onClick={() => goToSwapTower("swap")}>
                            <div className={styles.swap_matic_icon}>
                            <img className="logo" src={iconSwapMatic} />
                            </div>
                            <div className={styles.swap_matic_text}>
                                <div className={styles.swap_matic_text_title}>Swap $MATIC To $TOWER</div>
                                <div className={styles.swap_matic_text_subtitle}>Swap your $MATIC To $TOWER</div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className={styles.addtower_modal_footer}>
                        <div className={styles.addtower_modal_footer_earntower_text}>
                            <span>Want to play game and earn TOWER?</span>
                        </div>
                        <div className={styles.addtower_modal_footer_earntower_link} onClick={() => goToEarnTower()}>
                            <span>Earn TOWER</span>
                        </div>
                    </Modal.Footer>
                </Modal>  
            )
        : <></>
    );

}

export { AddTowerModal };
