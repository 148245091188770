import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './discordBoard.module.scss';
import { useLocation } from 'react-router-dom';
import google_store_btn from '../../assets/imgs/btn_google_play_new.png';
import apple_store_btn from '../../assets/imgs/btn_apple_store_new.png';
// import qr_cdh from '../../assets/imgs/qr-with-cdh-img.svg';
import BattleCardsImage from '../../assets/imgs/CoinbaseHome/battle_cards.png';
import TowerTicketImage from '../../assets/imgs/gold_ticket.png';
import OpenSeaLogo from '../../assets/icons/icon-opensea-simple.svg';
import OnePlanetLogo from '../../assets/icons/icon-oneplanet.png';
import MagicEdenLogo from '../../assets/icons/icon-magic-eden.png';
import RaribleLogo from '../../assets/icons/icon-rarible.png';
import BinanceNFTLogo from '../../assets/icons/icon-binance-nft.png';
import CDHGameLogo from '../../assets/imgs/cdh-profile-logo.png';

import QROthers from '../../assets/imgs/QR codes/TOWER Website - Others - main - 23 Dec 2022.svg';
import QRCoinbaseOthers from '../../assets/imgs/QR codes/TOWER Website - others - coinbase - 23 Dec 2022.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { MarketPlacesModal } from '../MarketPlacesModal';

const CoinbaseCampaignName = process.env.REACT_APP_COINBASE_CAMPAIGN_NAME;

const cdhGpLink = "https://go.onelink.me/bUzl/9c836b67";
const cdhAppStoreLink = "https://go.onelink.me/VHLW/e01c1c3f";

const TowerBattleCardsMarketPlaces = [
    {
        title: "OpenSea",
        image: OpenSeaLogo,
        link: "https://opensea.io/TOWER-Token/created",
    },
    {
        title: "OnePlanet",
        image: OnePlanetLogo,
        link: "https://www.oneplanetnft.io/collections/items/0xcf501ead3ebfa60f40725fca1d82fc05bff63bf2?utm_source=oneplanet&utm_medium=display&utm_campaign=main+banner&sort=price_low_to_high",
    },
    {
        title: "Magic Eden",
        image: MagicEdenLogo,
        link: "https://polygon.magiceden.io/collections/polygon/0xcf501ead3ebfa60f40725fca1d82fc05bff63bf2",
    },
    {
        title: "Rarible",
        image: RaribleLogo,
        link: "https://rarible.com/tower-battle-cards/items",
    },
];

const TowerTicketsPolygonMarketPlaces = [
    {
        title: "OpenSea",
        image: OpenSeaLogo,
        link: "https://opensea.io/TOWER-Token/created",
    },
    {
        title: "OnePlanet",
        image: OnePlanetLogo,
        link: "https://www.oneplanetnft.io/collections/items/0xcf501ead3ebfa60f40725fca1d82fc05bff63bf2?utm_source=oneplanet&utm_medium=display&utm_campaign=main+banner&sort=price_low_to_high",
    },
    {
        title: "Magic Eden",
        image: MagicEdenLogo,
        link: "https://polygon.magiceden.io/collections/polygon/tower-treasury?attributes=%7B%22Type%22%3A%5B%22TOWER+Ticket%22%5D%7D",
    },
    {
        title: "Rarible",
        image: RaribleLogo,
        link: "https://rarible.com/collection/polygon/0x2b88ce7b01e6bdbb18f9703e01286608cf77e805/items?filter[filter][traits][Type][key]=Type&filter[filter][traits][Type][values][]=TOWER%20Ticket",
    },
];

const TowerTicketsBinanceMarketPlaces = [
    {
        title: "Binance NFT",
        image: BinanceNFTLogo,
        link: "https://www.oneplanetnft.io/collections/items/0xcf501ead3ebfa60f40725fca1d82fc05bff63bf2?utm_source=oneplanet&utm_medium=display&utm_campaign=main+banner&sort=price_low_to_high",
    },
];

export const DiscordBoard = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const [hideBoard, setHideBoard] = useState(false);
    const [screenLoc, setScreenLoc] = useState(window.scrollY);
    const [collapsed, setCollapsed] = useState(false);
    const [showMarketplaceLinksModal, setShowMarketplaceLinksModal] = useState(false);

    const getScrollPercentage = (element) => {
        let parent = element.parentNode;
        return Math.ceil((element.scrollTop || parent.scrollTop) / (parent.scrollHeight - parent.clientHeight ) * 100);
    }

    const updateScrollLoc = () => {
        setScreenLoc(window.scrollY);
    }

    window.addEventListener('scroll', updateScrollLoc);

    useEffect(() => {
        let screenWidth = window.innerWidth;
        // Using 640 as width of screen matching to CSS media query.
        if (screenWidth <= 640) {
            setCollapsed(true);
        }
    }, [])

    useEffect(() => {
        let scrollPercentage = getScrollPercentage(window.document.body) || 0;
        if ((location.pathname === "/" && scrollPercentage < 4) || scrollPercentage === 100) {
            setHideBoard(true);
        } else {
            setHideBoard(false);
        }
    }, [screenLoc, location])

    return (
        <>
            {!hideBoard &&
                <div className={`${styles.discord_container} ${collapsed ? styles.collapsed : ''}`}>
                    <div className={styles.wooden_board}>
                        <div
                            className={styles.title}
                            onClick={() => setCollapsed(collapsed => !collapsed)}
                        >
                            {collapsed ? t('DOWNLOAD_APP_PURCHASE_NFTS') : t('SCAN_TO_PLAY')}
                            {collapsed ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
                        </div>
                        <div className={styles.store_container}>
                            <img src={CoinbaseCampaignName === window.location.host.split(".")[0] ? QRCoinbaseOthers : QROthers} alt="app-store-qr" className={styles.app_qr} />
                            <div className={styles.store_btns}>
                                <a href={cdhAppStoreLink} target="_blank" rel="noopener noreferrer">
                                    <img src={apple_store_btn} alt="apple-app-store" className={styles.app_store_btn} />
                                </a>
                                <a href={cdhGpLink} target="_blank" rel="noopener noreferrer">
                                    <img src={google_store_btn} alt="google-app-store" className={styles.app_store_btn} />
                                </a>
                            </div>
                        </div>
                        <div className={styles.nfts_marketplace_btn} onClick={() => setShowMarketplaceLinksModal(true)}>
                            {t('PURCHASE_NFTS_ON_MARKETPLACES')}
                        </div>
                    </div>

                    <div className={styles.wooden_board_mobile}>
                        <div
                            className={styles.title}
                            onClick={() => setCollapsed(collapsed => !collapsed)}
                        >
                            {t('DOWNLOAD_APP_PURCHASE_NFTS')}
                            {collapsed ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
                        </div>
                        <div className={styles.contents}>
                            <div className={styles.section_title}>{t('DOWNLOAD_APP')}</div>
                            <div className={styles.app_store_links}>
                                <img src={CDHGameLogo} alt="cdh app" className={styles.app_img} />
                                <div className={styles.store_btns}>
                                    <a href={cdhAppStoreLink} target="_blank" rel="noopener noreferrer">
                                        <img src={apple_store_btn} alt="apple-app-store" className={styles.app_store_btn} />
                                    </a>
                                    <a href={cdhGpLink} target="_blank" rel="noopener noreferrer">
                                        <img src={google_store_btn} alt="google-app-store" className={styles.app_store_btn} />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className={styles.mobile_horizontal_separator}></div>

                        <div className={styles.contents}>
                            <div className={styles.section_title}>{t('PURCHASE_NFTS_ON_MARKETPLACES')}</div>
                            <div className={styles.marketplace_container}>
                                <div className={styles.marketplace_container_title}>{t('PURCHASE_TOWER_BATTLE_CARDS')}</div>
                                <div className={styles.marketplace_links_container}>
                                    <img src={BattleCardsImage} alt="" className={styles.marketplace_type_icon} />
                                    
                                    <div className={styles.marketplace}>
                                        <div className={styles.marketplace_links}>
                                            {TowerBattleCardsMarketPlaces.map((marketplace, index) => (
                                                <div className={styles.marketplace_link} key={index}>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={(
                                                            <Tooltip id="button-tooltip">
                                                                {marketplace.title}
                                                            </Tooltip>
                                                        )}
                                                    >
                                                        <a href={marketplace.link} target="_blank" rel="noopener noreferrer">
                                                            <img src={marketplace.image} alt={marketplace.title} />
                                                        </a>
                                                    </OverlayTrigger>
                                                </div>
                                            ))}
                                        </div>
                                        <div className={styles.chain_text}>*{t('ON_POLYGON_CHAIN')}</div>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.marketplace_container}>
                                <div className={styles.marketplace_container_title}>{t('PURCHASE_TOWER_TICKETS')}</div>
                                <div className={styles.marketplace_links_container}>
                                    <img src={TowerTicketImage} alt="" className={styles.marketplace_type_icon} />
                                    
                                    <div>
                                        <div className={styles.marketplace}>
                                            <div className={styles.marketplace_links}>
                                                {TowerTicketsPolygonMarketPlaces.map((marketplace, index) => (
                                                    <div className={styles.marketplace_link} key={index}>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            delay={{ show: 250, hide: 400 }}
                                                            overlay={(
                                                                <Tooltip id="button-tooltip">
                                                                    {marketplace.title}
                                                                </Tooltip>
                                                            )}
                                                        >
                                                            <a href={marketplace.link} target="_blank" rel="noopener noreferrer">
                                                                <img src={marketplace.image} alt={marketplace.title} />
                                                            </a>
                                                        </OverlayTrigger>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className={styles.chain_text}>*{t('ON_POLYGON_CHAIN')}</div>
                                        </div>

                                        <div className={styles.horizontal_separator}></div>

                                        <div className={`${styles.second_row} d-flex`}>
                                            <div className={styles.marketplace}>
                                                <div className={styles.marketplace_links}>
                                                    {TowerTicketsBinanceMarketPlaces.map((marketplace, index) => (
                                                        <div className={styles.marketplace_link} key={index}>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={(
                                                                    <Tooltip id="button-tooltip">
                                                                        OpenSea
                                                                    </Tooltip>
                                                                )}
                                                            >
                                                                <a href={'https://opensea.io/collection/binance-bnb?search[query]=tower%20ticket'} target="_blank" rel="noopener noreferrer">
                                                                    <img src={OpenSeaLogo} alt='opensea bnb' />
                                                                </a>
                                                            </OverlayTrigger>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className={styles.chain_text}>*{t('ON_BNB_CHAIN')}</div>
                                            </div>

                                            <div className={styles.vertical_separator}></div>

                                            <div className={styles.marketplace}>
                                                <div className={styles.marketplace_links}>
                                                    {TowerTicketsBinanceMarketPlaces.map((marketplace, index) => (
                                                        <div className={styles.marketplace_link} key={index}>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={(
                                                                    <Tooltip id="button-tooltip">
                                                                        {marketplace.title}
                                                                    </Tooltip>
                                                                )}
                                                            >
                                                                <a href={marketplace.link} target="_blank" rel="noopener noreferrer">
                                                                    <img src={marketplace.image} alt={marketplace.title} />
                                                                </a>
                                                            </OverlayTrigger>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className={styles.chain_text}>*{t('ON_BINANCE_NFT')}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <MarketPlacesModal
                show={showMarketplaceLinksModal}
                showSetter={setShowMarketplaceLinksModal}
            />
        </>
    );
};
