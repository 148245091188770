import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import towerLogo from '../../assets/imgs/logo-tower.svg';
import IconNew from '../../assets/imgs/new_text.gif';
import styles from './SideNav.module.scss';

// const CoinbaseCampaignName = process.env.REACT_APP_COINBASE_CAMPAIGN_NAME;

export function SideNav(props) {
    const [t] = useTranslation();
    const history = useHistory();
    const [showAboutSections, setShowAboutSections] = useState(false);

    // const toggleAboutSectionMenus = () => {
    //     if (showAboutSections) {
    //         setShowAboutSections(false);
    //     } else {
    //         setShowAboutSections(true);
    //     }
    // };

    const scrollToLatestNews = () => {
        history.push('/news', { news: true });
    };

    const goToInventory = () => {
        history.push('/inventory/cards');
    };

    const goToChestSale = () => {
        history.push('/chest-sale');
    };
    
    const goToBuyTower = () => {
        history.push('/buy-tower/matic');
    };

    const goToEarnTower = () => {
        history.push('/earn-tower');
    };

    const goToAchievements = () => {
        history.push('/achievements');
    }

    const goToHomeTower = () => {
        history.push('/tower-token-intro', { tower_token_intro: true });
    };

    const goToHomePlatform = () => {
        history.push('/platform', { platform: true });
    };

    const goToHomeUtility = () => {
        history.push('/utility', { utility: true });
    };
    
    const scrollToWhereToGetTower = () => {
        history.push('/get-tower', { get_tower: true });
    };
    
    const scrollToTowerIsAvailableOn = () => {
        history.push('/available-on', { available_on: true });
    };

    const RenderDefaultLinks = (
        <div className={styles.nav_links}>
            <div className={`${styles.link} ${styles.about_link}`} onMouseEnter={() => setShowAboutSections(true)} onMouseLeave={() => setShowAboutSections(false)}>
                {t('About')}
                <FontAwesomeIcon icon={faChevronRight} />
                
                {showAboutSections && <div className={styles.link_submenus} onMouseEnter={() => setShowAboutSections(true)}>
                    <div className={styles.about_contents}>
                        <div className={styles.about_page_link} onClick={() => goToHomeTower()}>{t('WHAT_IS_TOWER')}</div>
                        <div className={styles.about_page_link} onClick={() => goToHomePlatform()}>{t('PLATFORM')}</div>
                        <div className={styles.about_page_link} onClick={() => goToHomeUtility()}>{t('UTILITY')}</div>
                        <div className={styles.about_page_link} onClick={() => scrollToWhereToGetTower()}>{t('WHERE_GET_TOWER')}</div>
                        <div className={styles.about_page_link} onClick={() => scrollToTowerIsAvailableOn()}>{t('DEXS_AND_CEXS')}</div>
                        <div className={styles.about_page_link} onClick={() => scrollToLatestNews()}>{t('NEWS')}</div>
                    </div>
                </div>}
            </div>
            <div className={styles.link} onClick={() => history.push('/fusion')}>
                <img src={IconNew} alt="new" className={styles.store_new_text_img} />
                {t('Unknown Device')}
            </div>
            <div className={styles.link} onClick={goToBuyTower}>{t('SWAP_TOWER')}</div>
            <div className={styles.link} onClick={goToEarnTower}>{t('EARN_TOWER')}</div>
            <div className={styles.link} onClick={() => history.push('/store')}>
                <img src={IconNew} alt="new" className={styles.store_new_text_img} />
                {t('TOWER Web Store')}
            </div>
            {/* <div className={styles.link} onClick={goToChestSale}>{t('CHEST_SALE')}</div> */}
            <div className={styles.link} onClick={goToAchievements}>{t('ACHIEVEMENTS')}</div>
            <div className={styles.link} onClick={goToInventory}>{t('INVENTORY')}</div>
            <div className={styles.link} onClick={() => history.push('/cdh-x-bayc')}>
                {/* <img src={IconNew} alt="new" className={styles.store_new_text_img} /> */}
                CDH x BAYC
            </div>
            <div className={styles.link} onClick={() => history.push('/tower-league')}>
                {t('TOWER_LEAGUE')}
            </div>
        </div>
    );

    const RenderCoinbaseLinks = (
        <div className={styles.nav_links}>
            <div className={`${styles.link} ${styles.about_link}`} onMouseEnter={() => setShowAboutSections(true)} onMouseLeave={() => setShowAboutSections(false)}>
                {t('ABOUT')}
                <FontAwesomeIcon icon={faChevronRight} />
                
                {showAboutSections && <div className={styles.link_submenus} onMouseEnter={() => setShowAboutSections(true)}>
                    <div className={styles.about_contents}>
                        <HashLink to="/#events"><div className={styles.about_page_link}>{t('EVENTS')}</div></HashLink>
                        <HashLink to="/#what-is-tower"><div className={styles.about_page_link}>{t('WHAT_IS_TOWER')}?</div></HashLink>
                        <HashLink to="/#game-play-and-earn"><div className={styles.about_page_link}>{t('GAME_PLAY_EARN')}</div></HashLink>
                        <HashLink to="/#nfts-hold-claim-stake"><div className={styles.about_page_link}>{t('HOLD_TOWER_NFTS_EARN_REWARDS')}</div></HashLink>
                        <HashLink to="/#tower-league-hero-points"><div className={styles.about_page_link}>{t('TOWER_LEAGUE_HERO_POINTS')}</div></HashLink>
                        <HashLink to="/#web-store"><div className={styles.about_page_link}>{t('WEB_STORE')}</div></HashLink>
                        <HashLink to="/#news"><div className={styles.about_page_link}>{t('NEWS')}</div></HashLink>
                    </div>
                </div>}
            </div>
            
            <div className={styles.link} onClick={goToBuyTower} id="left_menu_get_tower">{t('GET_TOWER')}</div>
            <div className={styles.link} onClick={() => history.push('/earn-tower')} id="left_menu_earn_tower">
                {t('EARN_TOWER')}
            </div>
            <div className={styles.link} onClick={() => history.push('/crazy-kings')}>
                <img src={IconNew} alt="new" className={styles.store_new_text_img} />
                {t('Crazy Kings')}
            </div>
            <div className={styles.link} onClick={() => history.push('/tower-league')} id="left_menu_tower_league">
                <img src={IconNew} alt="new" className={styles.store_new_text_img} />
                {t('TOWER_LEAGUE')}
            </div>
            <div className={styles.link} onClick={() => history.push('/store')} id="left_menu_web_store">
                {t('WEB_STORE')}
            </div>
            <div className={styles.link} onClick={() => history.push('/achievements')}>
                {t('ACHIEVEMENTS')}
            </div>
            <div className={styles.link} onClick={() => history.push('/inventory/cards')}>
                {t('INVENTORY')}
            </div>
            <div className={styles.link} onClick={() => history.push('/fusion')} id="left_menu_unknown_device">
                {t('UNKNOWN_DEVICE')}
            </div>
            <div className={styles.link} onClick={() => history.push('/cdh-x-bayc')}>
                BAYC
            </div>
        </div>
    );

    return (
        <>
            <div className={styles.side_nav_container}>
                <div className={styles.sidenav_head}>
                    <img onClick={() => history.push('/')} src={towerLogo} className={styles.tower_logo} alt="tower-logo"></img>
                    {/* {CoinbaseCampaignName === window.location.host.split(".")[0]
                        ? RenderCoinbaseLinks
                        : RenderDefaultLinks
                    } */}
                    {RenderCoinbaseLinks}
                </div>
            </div>
        </>
    );
}
