import React, { useEffect, lazy, Suspense, useContext } from 'react';
import dotenv from 'dotenv';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import {
  Footer,
  Navigation,
  SideNav,
} from '../components';
import { Web3Provider } from '../web3';
import { CookiesProvider } from 'react-cookie';
import './App.scss';
import { IpProvider, TokenProvider, ABIContext, UserProvider } from '../components/Context';
import { initializeDeploymentContexts, initializeProviders } from '../components/Context/Actions';
import { CustomSwitch } from '../components/CustomSwitch';
import CardDetail from '../components/CardDetails/CardDetail';
import { DiscordBoard } from '../components/Footer/discordBoard';
import { SocialMediaSidebar } from '../components/SocialMediaSidebar/SocialMediaSidebar';
import Loading from '../assets/imgs/loader.gif';
import CautionIcon from '../assets/icons/icon-caution.svg';
import { GeoIPChecker } from '../components/GeoIPChecker';
import { TowerXBaycPage } from '../pages/TowerXBaycPage';

const MobileBannerRedirectionUrl = process.env.REACT_APP_MOBILE_BANNER_REDIRECT_URL;
// const CoinbaseCampaignName = process.env.REACT_APP_COINBASE_CAMPAIGN_NAME;

dotenv.config({ debug: true });
// const Home = lazy(() => import('./routes/Home'));
const Inventory = lazy(() => import('./routes/Inventory'));
const PopNFT = lazy(() => import('./routes/PopNFT'));
const Bridge = lazy(() => import('./routes/Bridge'));
const PrivacyPolicy = lazy(() => import('./routes/PrivacyPolicy'));
const BinanceNFT = lazy(() => import('./routes/BinanceNFT'));
const ChestSalePage = lazy(() => import('./routes/ChestSalePage'));
const EarnTower = lazy(() => import('./routes/EarnTower'));
const BuySwapTower = lazy(() => import('./routes/BuySwapTower'));
const SimplexCheckout = lazy(() => import('./routes/SimplexCheckout'));
const CDHStore = lazy(() => import('./routes/CDHStore'));
const Achievements = lazy(() => import('./routes/Achievements'));
const Fusion = lazy(() => import('./routes/Fusion'));
const TowerLeague = lazy(() => import('./routes/TowerLeague'));
const CoinbaseHome = lazy(() => import('./routes/CoinbaseHome'));
const CrazyKings = lazy(() => import('./routes/CrazyKings'));

library.add(fab, fas);

const defaultContainer = () => (
  <div>
    <SideNav />
    <SocialMediaSidebar />

    <Switch>
    {/* {CoinbaseCampaignName === window.location.host.split(".")[0]
      && <Route exact path='/' component={CoinbaseHome} />}
    
    <Route exact path='/' component={Home} />
    <Route exact path='/news' component={Home} />
    <Route exact path='/tower-token-intro' component={Home} />
    <Route exact path='/platform' component={Home} />
    <Route exact path='/utility' component={Home} />
    <Route exact path='/get-tower' component={Home} />
    <Route exact path='/available-on' component={Home} /> */}

    <Route exact path='/' component={CoinbaseHome} />
    <Route path='/binance-nft' component={BinanceNFT} />
    <Route path='/earn-tower' component={EarnTower} />
    <Route exact path='/chest-sale' component={ChestSalePage} />
    <Route path="/buy-tower" component={BuySwapTower} />
    <Route exact path='/achievements' component={Achievements} />
    <Route exact path='/inventory/:type' component={Inventory} />
    <Route exact path='/inventory/cards/:id' component={CardDetail} />
    <Route exact path='/bridge/:nftType' component={Bridge} />
    <Route exact path='/checkout/:paymentId?/:playerId?' component={SimplexCheckout} />
    <Route path='/store' component={CDHStore} />
    <Route path='/fusion' component={Fusion} />
    <Route path='/cdh-x-bayc' component={TowerXBaycPage} />
    <Route path='/tower-league' component={TowerLeague} />
    <Route path='/crazy-kings' component={CrazyKings} />

    {/* {CoinbaseCampaignName === window.location.host.split(".")[0]
      && <Route path='' component={CoinbaseHome} />}
    <Route path='' component={Home} /> */}

    <Route path='' component={CoinbaseHome} />
    </Switch>

    <Footer />
    <DiscordBoard />
  </div>
)

const AppAllowed = () => {
  const [abiConfig, abiDispatch] = useContext(ABIContext);

  useEffect(() => {
    (async () => {
      let providers = await initializeProviders();
      if (providers) {
        abiDispatch(providers);
      }
      let deploymentContexts = await initializeDeploymentContexts();
      if (deploymentContexts) {
        abiDispatch(deploymentContexts);
      }
    })();
  }, [])

  return (
    <div className="App">
      <IpProvider>
        <Web3Provider>
          <CookiesProvider>
            <TokenProvider>
              <UserProvider>
                <Router>
                  <CustomSwitch>
                    <Route path="*" component={Navigation} />
                  </CustomSwitch>
                  <div className="page-wrapper-content">
                    <Suspense fallback={<div className="loading"><img src={Loading} alt="loading" /></div>}>
                      <Switch>
                        {MobileBannerRedirectionUrl &&
                          <Route exact path='/mobilebanner' component={() => {
                            window.location.href = MobileBannerRedirectionUrl;
                            return null;
                          }} />
                        }
                        <Route path='/privacy-policy' component={PrivacyPolicy} />
                        <Route exact path='/open-chest' component={PopNFT} />
                        <Route component={defaultContainer} />
                      </Switch>
                    </Suspense>
                  </div>
                </Router>
              </UserProvider>
            </TokenProvider>
          </CookiesProvider>
        </Web3Provider>
      </IpProvider>
    </div>
  );
}

const AppBlocked = () => {
  return (
    <div id="app">
      <div className="app-blocked-notice">
        <div className="caution-icon">
          <img src={CautionIcon} alt="This service is not available in your region" />
        </div>
        <div className="blocked-text">
          <span>This service is not available in your region</span>
        </div>

      </div>
    </div>
  );
}

const App = () => {
  return <GeoIPChecker ComponentAllowed={AppAllowed} ComponentBlocked={AppBlocked} errorHandler={(e) => console.error(e)} />
}

export default App;
