import React from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import styles from './MarketPlacesModal.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import BattleCardsImage from '../../assets/imgs/CoinbaseHome/battle_cards.png';
import OpenSeaLogo from '../../assets/icons/icon-opensea-simple.svg';
import OnePlanetLogo from '../../assets/icons/icon-oneplanet.png';
import MagicEdenLogo from '../../assets/icons/icon-magic-eden.png';
import RaribleLogo from '../../assets/icons/icon-rarible.png';
import BinanceNFTLogo from '../../assets/icons/icon-binance-nft.png';
import { useTranslation } from 'react-i18next';

const TowerBattleCardsMarketPlaces = [
    {
        title: "OpenSea",
        image: OpenSeaLogo,
        link: "https://opensea.io/TOWER-Token/created",
    },
    {
        title: "OnePlanet",
        image: OnePlanetLogo,
        link: "https://www.oneplanetnft.io/collections/items/0xcf501ead3ebfa60f40725fca1d82fc05bff63bf2?utm_source=oneplanet&utm_medium=display&utm_campaign=main+banner&sort=price_low_to_high",
    },
    {
        title: "Magic Eden",
        image: MagicEdenLogo,
        link: "https://polygon.magiceden.io/collections/polygon/0xcf501ead3ebfa60f40725fca1d82fc05bff63bf2",
    },
    {
        title: "Rarible",
        image: RaribleLogo,
        link: "https://rarible.com/tower-battle-cards/items",
    },
];

const TowerTicketsPolygonMarketPlaces = [
    {
        title: "OpenSea",
        image: OpenSeaLogo,
        link: "https://opensea.io/TOWER-Token/created",
    },
    {
        title: "OnePlanet",
        image: OnePlanetLogo,
        link: "https://www.oneplanetnft.io/collections/items/0xcf501ead3ebfa60f40725fca1d82fc05bff63bf2?utm_source=oneplanet&utm_medium=display&utm_campaign=main+banner&sort=price_low_to_high",
    },
    {
        title: "Magic Eden",
        image: MagicEdenLogo,
        link: "https://polygon.magiceden.io/collections/polygon/tower-treasury?attributes=%7B%22Type%22%3A%5B%22TOWER+Ticket%22%5D%7D",
    },
    {
        title: "Rarible",
        image: RaribleLogo,
        link: "https://rarible.com/collection/polygon/0x2b88ce7b01e6bdbb18f9703e01286608cf77e805/items?filter[filter][traits][Type][key]=Type&filter[filter][traits][Type][values][]=TOWER%20Ticket",
    },
];

const TowerTicketsBinanceMarketPlaces = [
    {
        title: "Binance NFT",
        image: BinanceNFTLogo,
        link: "https://www.binance.com/en/nft/search-result?isVerified=1&assetType=1&keyword=TOWER+Ticket&tab=nft&order=amount_sort%401",
    },
];

const MarketPlacesModal = (props) => {
    const { show, showSetter, category } = props;
    const { t } = useTranslation();

    return (
        <Modal
            show={show}
            dialogClassName={styles.nft_marketplace_links_modal}
            contentClassName={styles.nft_marketplace_links_modal_content}
            onHide={() => showSetter(false)}
            centered
        >
            <div className={styles.modal_close_btn} onClick={() => showSetter(false)}><FontAwesomeIcon icon={faTimes}/></div>
            <Modal.Body className={styles.nft_marketplace_links_modal_body}>
                {(!category || category === 'cards') &&
                    <>
                        <h3 className={styles.nft_marketplace_links_modal_title}>{t('PURCHASE_TOWER_BATTLE_CARDS_BELOW_MARKETPLACES')}</h3>
                        <div className={styles.tower_cards_marketplace_links_container}
                            style={{
                                flexDirection: category === 'cards' ? 'column' : '',
                                alignItems: category === 'cards' ? 'center' : ''
                            }}
                        >
                            <div className={styles.tower_cards_img} style={{ marginRight: category === 'cards' ? '0px' : '' }}>
                                <img src={BattleCardsImage} alt="battle cards" />
                            </div>

                            <div className={styles.marketplace} style={{ marginTop: category === 'cards' ? '16px' : '' }}>
                                <div className={styles.marketplace_links}>
                                    {TowerBattleCardsMarketPlaces.map((marketplace, index) => (
                                        <div className={styles.marketplace_link} key={index}>
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={(
                                                    <Tooltip id="button-tooltip">
                                                        {marketplace.title}
                                                    </Tooltip>
                                                )}
                                            >
                                                <a href={marketplace.link} target="_blank" rel="noopener noreferrer">
                                                    <img src={marketplace.image} alt={marketplace.title} />
                                                </a>
                                            </OverlayTrigger>
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.chain_text}>*{t('ON_POLYGON_CHAIN')}</div>
                            </div>
                        </div>
                    </>
                }

                {!category && <div className={styles.horizontal_separator}></div>}

                {(!category || category === 'tickets') &&
                    <>
                        <h3 className={styles.tickets_marketplace_links_modal_title}>{t('PURCHASE_TOWER_TICKETS_BELOW_MARKETPLACES')}</h3>
                        <div className={styles.tower_cards_marketplace_links_container}>
                            <div className={styles.marketplace}>
                                <div className={styles.marketplace_links}>
                                    {TowerTicketsPolygonMarketPlaces.map((marketplace, index) => (
                                        <div className={styles.marketplace_link} key={index}>
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={(
                                                    <Tooltip id="button-tooltip">
                                                        {marketplace.title}
                                                    </Tooltip>
                                                )}
                                            >
                                                <a href={marketplace.link} target="_blank" rel="noopener noreferrer">
                                                    <img src={marketplace.image} alt={marketplace.title} />
                                                </a>
                                            </OverlayTrigger>
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.chain_text}>*{t('ON_POLYGON_CHAIN')}</div>
                            </div>
                            
                            <div className={styles.vertical_separator}></div>

                            <div className={styles.marketplace}>
                                <div className={styles.marketplace_links}>
                                    {TowerTicketsBinanceMarketPlaces.map((marketplace, index) => (
                                        <div className={styles.marketplace_link} key={index}>
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={(
                                                    <Tooltip id="button-tooltip">
                                                        OpenSea
                                                    </Tooltip>
                                                )}
                                            >
                                                <a href={'https://opensea.io/collection/binance-bnb?search[query]=tower%20ticket'} target="_blank" rel="noopener noreferrer">
                                                    <img src={OpenSeaLogo} alt='opensea bnb' />
                                                </a>
                                            </OverlayTrigger>
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.chain_text}>*{t('ON_BNB_CHAIN')}</div>
                            </div>

                            <div className={styles.vertical_separator}></div>

                            <div className={styles.marketplace}>
                                <div className={styles.marketplace_links}>
                                    {TowerTicketsBinanceMarketPlaces.map((marketplace, index) => (
                                        <div className={styles.marketplace_link} key={index}>
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={(
                                                    <Tooltip id="button-tooltip">
                                                        {marketplace.title}
                                                    </Tooltip>
                                                )}
                                            >
                                                <a href={marketplace.link} target="_blank" rel="noopener noreferrer">
                                                    <img src={marketplace.image} alt={marketplace.title} />
                                                </a>
                                            </OverlayTrigger>
                                        </div>
                                    ))}
                                </div>
                                <div className={styles.chain_text}>*{t('ON_BINANCE_NFT')}</div>
                            </div>
                        </div>
                    </>
                }
            </Modal.Body>
        </Modal>
    );
}

export { MarketPlacesModal };