import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';
import styles from './Spinner.module.scss';
import { useTranslation } from 'react-i18next';
import iconPolygon from '../../assets/icons/icon-polygon.png';
import loader from '../../assets/imgs/loader.gif';

const Spinner = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal show={props.show} className={styles.bridge_modal} centered>
        <Modal.Body>
          <div className={styles.bridge_modal_body}>
            <>
              {props.headerText ? (
                <div className={styles.header_texts}>{t(props.headerText)}</div>
              ) : (
                <div className={styles.header_texts}></div>
              )}
              {/* <FontAwesomeIcon
                icon={faSpinner}
                spin
                className={styles.bridge_spinner}
              /> */}
              <img src={loader} alt="" />
              {props.bottomText ? (
                <div className={styles.bottom_texts}>{props.bottomText}</div>
              ) : (
                <div className={styles.bottom_texts}></div>
              )}
              <div className={styles.processing}>{t('PROCESSING')}</div>
              {props.link ? (
                <div className={styles.bottom_link}>
                  <a href={props.link}>
                    <img src={iconPolygon} alt='' />
                    {t('VIEW_POLYSCAN')}
                  </a>
                </div>
              ) : null}
            </>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Spinner;
