import React, { useEffect, useState, useContext, useRef } from 'react';
import { Container, Image, Button, Modal, Overlay, Tooltip } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { Trans, useTranslation } from 'react-i18next';
import styles from './ClaimTower.module.scss';
import { ConnectModal, SignInWithApple } from '../../components';
import { IpContext } from '../Context';
import firebase from "firebase";
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import { Web3Context } from '../../web3/Web3Context';
import loadingWheel from '../../assets/imgs/bind/loader-v01.gif';
import errorImage from '../../assets/imgs/bind/img-cannot-view-narrator.png';
import { middleEllipsis } from '../../helpers';
import androidLogo from '../../assets/icons/icon-android-wht-circle.svg';
import appleLogo from '../../assets/icons/icon-apple-store-wht-circle.svg';
import cdhLogo from '../../assets/imgs/bind/img-logo-cdh.png';
import metamaskLogo from '../../assets/icons/icon-metamask.png';
import witchImage from '../../assets/imgs/bind/img-d-witcher.png';
// import headerImg from '../../assets/imgs/header_img.png';
import archerImage from '../../assets/imgs/bind/img-d-archer.png';
import claimHeaderBoxImg from '../../assets/imgs/bind/img-title-red-ball.png';
import bindBoxDivider from '../../assets/imgs/bind/img-d-line-long.png';
import bindBoxDividerShort from '../../assets/imgs/bind/img-d-line-short.png';
import levelIcon from '../../assets/imgs/bind/icon-level.png';
import gemIcon from '../../assets/imgs/bind/icon-gem.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faLink, faSignOutAlt, faCopy, faCheck, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { getWallet, bindWallet, loginWithId } from '../../services/walletService';
import { getRemoteValues, getCdhUserDetails, verifySignInWithApple, getCdhUserIdFromSiwaAuth } from '../../services/cdhService';
import { copyToClipboard } from '../../helpers/copyToClipboard';
import discordLogo from '../../assets/icons/icon-discord.svg';

const _second = 1000;
const _minute = _second * 60;
const _hour = _minute * 60;
const _day = _hour * 24;
const waitingForRedirectReturn = 3000;

const defaultWalletConnector = "metamask";
const signingPrefix = "Link account: ";
const cdhGpLink = "https://go.onelink.me/bUzl/9c836b67";
const cdhAppStoreLink = "https://go.onelink.me/VHLW/e01c1c3f";

const bindErrorCodes = {
    ERROR1: "walletUsed",
    ERROR2: "CdhUserUsed"
};

const ClaimTower = () => {
    const [t] = useTranslation();
    let {web3, connect, selectedAccount, ready} = useContext(Web3Context);
    const {isInChina, ipReady} = useContext(IpContext);
    const [cdhUserId, setCdhUserId] = useState(null)
    const [cdhUserDetails, setCdhUserDetails] = useState(null)
    const [towerPerson, setTowerPerson] = useState(null)
    const [fetchingUserData, setFetchingUserData] = useState(null);
    const [countdownTime, setCountdownTime] = useState({});
    const [bindAgreed, setBindAgreed] = useState(false);
    const [linkStartDate, setLinkStartDate] = useState(new Date(2050, 1, 1));
    const [linkEndDate, setLinkEndDate] = useState(new Date(2050, 1, 1));
    const [showCopied, setShowCopied] = useState(false);
    const [siwaCookies, setSiwaCookies, removeSiwaCookies] = useCookies(['SiwaAuthId']);
    const [bindErrorCode, setBindErrorCode] = useState(null);

    const [showBindErrorModal, setShowBindErrorModal] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showBindModal, setShowBindModal] = useState(false);
    const [bindingWallet, setBindingWallet] = useState(false);
    const [showMobileLinkHelp, setShowMobileLinkHelp] = useState(false);

    const web3WalletConnected = selectedAccount && ready;
    const loginDisabled = !web3WalletConnected || web3WalletConnected && cdhUserId != null;
    const bindDisabled = !web3WalletConnected || web3WalletConnected && cdhUserId == null || towerPerson != null && towerPerson.WalletId.toLowerCase() == selectedAccount.toLowerCase();
    const registered = web3WalletConnected && cdhUserId != null && towerPerson != null && towerPerson.WalletId.toLowerCase() == selectedAccount.toLowerCase();

    const dateNow = new Date();
    const copyTarget = useRef(null);

    var commonFirebaseCallback = {
        'signInSuccessWithAuthResult': function(authResult, redirectUrl) {
            setShowLoginModal(false);
            return false;
        }
    }

    var commonFirebaseSignInOption = [
        'apple.com',
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        {
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            disableSignUp: {
                status: true
            },
            fullLabel: t('SIGN_IN_WITH_EMAIL')
        },
        {
            provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
            fullLabel: t('SIGN_IN_WITH_PHONE')
        }
    ]

    var uiConfigMobile = {
        signInSuccessUrl: '/',
        callbacks: commonFirebaseCallback,
        signInOptions: commonFirebaseSignInOption
    };

    var uiConfig = {
        signInFlow: 'popup',
        callbacks: commonFirebaseCallback,
        signInOptions: commonFirebaseSignInOption
    };

    const postProcessAppleSignedIn = async (idToken) => {
        setFetchingUserData(true);
        var response = await verifySignInWithApple(idToken, isInChina);
        if (response != null) {
            var authId = response.AuthId;
            setSiwaCookies('SiwaAuthId', authId, { path: '/' });
            loginSiwaUser(authId);
            setShowLoginModal(false);
            setFetchingUserData(false);
        } else {
            setFetchingUserData(false);
        }
    }

    const loginSiwaUser = async (authId) => {
        var cdhFromAuth = await getCdhUserIdFromSiwaAuth(authId, isInChina);
        var cdhPlayerId = cdhFromAuth.Username
        if (cdhPlayerId != "") {
            setCdhUserId(cdhPlayerId)
            getUserData("", authId, cdhPlayerId)
        } else {
            setFetchingUserData(false);
        }
    }

    const startTimer = (_endDate) => {
        const interval = setInterval(() => {
            const timeNow = new Date();
            var result = new Date(_endDate - timeNow);

            var d = Math.floor(result / _day);
            var h = Math.floor((result % _day) / _hour);
            var m = Math.floor((result % _hour) / _minute);
            var s = Math.floor((result % _minute) / _second);

            setCountdownTime({ days: d, hours: h, minutes: m, seconds: s });
        }, 1000);

        return () => clearInterval(interval);
    };

    const getUserData = async (firebaseuuid, siwaId, cdhPlayerId) => {
        var response = await loginWithId(firebaseuuid, siwaId, cdhPlayerId, isInChina);
        var userId = response == null || response.CdhPlayerId.length == 0 ? null : response.CdhPlayerId;
        if (userId != null) {
            setCdhUserId(userId);

            const userDetails = await getCdhUserDetails(userId, isInChina);
            setCdhUserDetails(userDetails);

            var responseTowerPerson = await getWallet(firebaseuuid, siwaId, isInChina);
            setTowerPerson(responseTowerPerson);
        }

        setFetchingUserData(false);
    }

    const handleBindWallet = async () => {
        var firebaseUser = firebase.auth().currentUser;
        var firebaseuuid = firebaseUser != null ? firebaseUser.uid : null;
        var siwaId = siwaCookies.SiwaAuthId != null ? siwaCookies.SiwaAuthId : null;
        var signMessage = firebaseUser != null ? firebaseuuid : siwaId;
        var prefixedSignMessage = signingPrefix + signMessage;
        setBindingWallet(true);

        try {
            if(web3 == null) {
                web3 = await connect({connector: defaultWalletConnector});
            }
            const signature = await web3.eth.personal.sign(prefixedSignMessage, selectedAccount, undefined);
            const responseTowerPerson = await bindWallet(firebaseuuid, siwaId, signature, selectedAccount, defaultWalletConnector, cdhUserId, isInChina);
            if (responseTowerPerson != null) {
                setTowerPerson(responseTowerPerson);
            }
            else {
                showBindErrorModalWithErrorCode(bindErrorCodes.ERROR1);
            }
            setBindingWallet(false);
            setShowBindModal(false);
        } catch (e) {
            console.log(e);
            setBindingWallet(false);
            setShowBindModal(false);
        }
    }

    const signOut = () => {
        removeSiwaCookies('SiwaAuthId', { path: '/' });
        handleSignedOutUser();
        if (!isInChina) {
            firebase.auth().signOut();
        }
    }

    const GetFirebaseConfig = () => {
        var config = uiConfig;
        if( /Android|webOS|iPhone|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            config = uiConfigMobile;
        }

        return config;
    }

    const HandleShowLoginModalOnShow = () => {
        if (!isInChina) {
            var config = GetFirebaseConfig();
            var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
            ui.start('#firebaseui-auth-container2', config);
        }
    }

    const initializeFirebase = () => {
        var config = GetFirebaseConfig();

        if (!isInChina) {
            var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
            // If method is redirect, we need to add a delay to make sure we catch the return
            // before running start, as start "completes" the redirect flow
            if (ui.isPendingRedirect()){
                setFetchingUserData(true);

                // It seems cookies does not persist in metamask app
                // Hack to connect it first when redirected back my firebase
                if(web3 == null) {
                    connect({connector: defaultWalletConnector});
                }
                setTimeout(function () {
                    ui.start('#firebaseui-auth-container', config);
                    setFetchingUserData(false);
                }, waitingForRedirectReturn);
            } else {
                ui.start('#firebaseui-auth-container', config);
            }
        }
    }

    const handleSignedInUser = (user) => {
        setFetchingUserData(true);
        getUserData(user.uid, "", "");
    }

    const handleSignedOutUser = () => {
        setCdhUserId(null);
        setTowerPerson(null);
    }

    const handleBindButtonClicked = () => {
        if (towerPerson == null) {
            setShowBindModal(true);
        }
        else {
            if (towerPerson.WalletId.toLowerCase() != selectedAccount.toLowerCase()) {
                showBindErrorModalWithErrorCode(bindErrorCodes.ERROR2);
            }
            else {
                console.log("Should already be in registed state, something went wrong");
            }
        }
    }

    const showBindErrorModalWithErrorCode = (errorCode) => {
        setBindErrorCode(errorCode);
        setShowBindErrorModal(true);
    }

    const copyWalletAddress = (textToCopy) => {
        setShowCopied(true);
        copyToClipboard(textToCopy);
    }

    useEffect(() => {
        const fetchRemoveValues = async () => {
            setFetchingUserData(true);
            const response = await getRemoteValues(isInChina);
            if (response != null) {
                var startDate = new Date(response.LinkStartDate);
                var endDate = new Date(response.LinkEndDate);

                setLinkStartDate(startDate);
                setLinkEndDate(endDate);

                if (dateNow > startDate && dateNow < endDate) {
                    startTimer(endDate);
                }
            }

            if (!isInChina) {
                var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
                if (!ui.isPendingRedirect()) {
                    setFetchingUserData(false);
                }
            } else {
                setFetchingUserData(false);
            }
        }

        if (ipReady) {
            fetchRemoveValues();
        }
    }, [isInChina, ipReady]);

    useEffect(() => {
        initializeFirebase();

        if (isInChina && ipReady) {
            if (siwaCookies.SiwaAuthId != null) {
                setFetchingUserData(true);
                loginSiwaUser(siwaCookies.SiwaAuthId);
            }
        } else {
            firebase.auth().onAuthStateChanged(function(user) {
                user != null ? handleSignedInUser(user) : handleSignedOutUser();
            });
        }
    }, [isInChina, ipReady])

    const boxFooter = (smallContainer) => {
        return <div className={`${styles.earn_tower_details} ${styles.bind_description} ${smallContainer ? styles.small_container : ""}`}>
            <img src={cdhLogo} className={styles.cdh_logo}/>
            <div className={styles.right_panel}>
                {t('PRE_LINK_WANNA_EARN_TOWER')}
                <div className={styles.stores_logo}>
                    <a href={cdhGpLink} target="_blank"><img src={androidLogo} /></a>
                    <a href={cdhAppStoreLink} target="_blank"><img src={appleLogo} /></a>
                </div>
            </div>
        </div>
    };

    const renderComingSoon = (
        <div className={styles.bind_soon_container}>
            <div className={styles.bind_container_items}>
                <div className={styles.bind_texts}>
                    <div className={`${styles.bind_title} ${styles.coming_soon_title}`}>{t('PRE_LINK_COMING_SOON')}</div>
                    <div className={`${styles.bind_subtitle} ${styles.coming_soon_subtitle}`}>{t('PRE_LINK_LINK_YOUR_WALLET_CDH_ID')}</div>
                    <div className={`${styles.bind_description} ${styles.coming_soon_description}`}>{t('EXTEND_LINK_CONTENT')}</div>
                </div>
                { boxFooter(true) }
            </div>
            <div className={styles.witch_image}><img src={witchImage} /></div>
        </div>
    );

    const renderBindPeriodEnded = (
        <div className={styles.bind_soon_container}>
            <div className={styles.bind_container_items}>
                <div className={styles.bind_texts}>
                    <div className={`${styles.bind_title} ${styles.coming_soon_title}`}>{t('LINKING_PERIOD_ENDED_TITLE')}</div>
                    <div className={`${styles.bind_subtitle} ${styles.coming_soon_subtitle}`}>{t('LINKING_PERIOD_ENDED_CONTENT')}</div>
                    <div className={`${styles.bind_description} ${styles.coming_soon_description}`}>{t('LINKING_PERIOD_ENDED_SUBTEXT')}</div>
                </div>
                { boxFooter(true) }
            </div>
            <div className={styles.witch_image}><img src={archerImage} /></div>
        </div>
    );

    const renderBindBoxHeader = (
        countdownTime != null
        ? <div className={styles.header}>
                <div className={`${styles.header_texts} ${styles.web_only}`}>
                    <div className={styles.header_subtitle}>{t('LINK_YOUR_WALLET_CDH_ID')}</div>
                    <div className={styles.header_description}>{t('LINK_YOUR_WALLET_CDH_ID_DETAILS')}</div>
                </div>

                {/*<div className={styles.countdown}>*/}
                {/*    <div className={styles.clock_box}>*/}
                {/*        <div>{countdownTime.days}</div>*/}
                {/*        <div>{t('DAY_KEY')}</div>*/}
                {/*    </div>*/}
                {/*    <div className={styles.clock_box}>*/}
                {/*        <div>{countdownTime.hours}</div>*/}
                {/*        <div>{t('HOUR_KEY')}</div>*/}
                {/*    </div>*/}
                {/*    <div className={styles.clock_box}>*/}
                {/*        <div>{countdownTime.minutes}</div>*/}
                {/*        <div>{t('MINUTE_KEY')}</div>*/}
                {/*    </div>*/}
                {/*    <div className={styles.clock_box}>*/}
                {/*        <div>{countdownTime.seconds}</div>*/}
                {/*        <div>{t('SECOND_KEY')}</div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/* <div className={styles.header_img}>
                    <img src={headerImg} alt="" />
                </div> */}
                <div className={`${styles.header_texts} ${styles.mobile_only}`}>
                    <div className={styles.header_subtitle}>{t('PRE_LINK_LINK_YOUR_WALLET_CDH_ID')}</div>
                    <div className={styles.header_description}>{t('EXTEND_LINK_CONTENT')}</div>
                </div>
            </div>
        : <></>
    );

    const renderNonRegistered = (
        <div className={styles.claim_box_container}>
            <div className={styles.claim_box_header_container}>
                <div className={styles.claim_box_header_img}><img src={claimHeaderBoxImg}/></div>
                <div className={styles.claim_box_header_bar}>{t('LINKING_WALLET_GAME_ID_LINKING')}</div>
            </div>
            { renderBindBoxHeader }
            <div className={styles.bind_box_divider_parent}><div className={styles.bind_box_divider}><img src={bindBoxDivider} /></div></div>
            <div className={styles.body}>
                <div className={styles.bind_section}>
                    <div className={styles.bind_section_title}>{t('THREE_STEPS_TO_BIND')}</div>
                    <div className={styles.steps_button_section}>
                        <div className={styles.bind_steps}>
                            <div className={`${styles.bind_step} ${styles.active}`}> {web3WalletConnected ? <FontAwesomeIcon icon={faCheck}/> : 1}</div>
                            <div className={styles.divider} />
                            <div className={web3WalletConnected && cdhUserId == null || !bindDisabled ? `${styles.bind_step} ${styles.active}` : styles.bind_step}>
                                { !bindDisabled ? <FontAwesomeIcon icon={faCheck}/> : 2 }
                            </div>
                            <div className={styles.divider} />
                            <div className={!bindDisabled ? `${styles.bind_step} ${styles.active}` : styles.bind_step}>3</div>
                        </div>
                        <div className={styles.bind_buttons}>
                            <div className={styles.step_button}>
                                <Button variant="cdh-general-blue-dbrown" disabled={web3WalletConnected} onClick={connect}>
                                    { web3WalletConnected ? t('WALLET_CONNECTED') : t('CONNECT_WALLET') }
                                </Button>
                            </div>
                            <div className={styles.step_button}>
                                { firebase.auth().currentUser != null || siwaCookies.SiwaAuthId != null
                                    ? <div className={styles.step_mini_container}>
                                            { cdhUserId == null
                                                ? <div>{t('NO_CDH_USER_FOUND')}</div>
                                                : <div>
                                                    <div className={styles.bind_description}>{t('GAME_ID')}</div>
                                                    <div className={styles.step_text}>{cdhUserId}</div>
                                                </div>
                                            }
                                            <div onClick={signOut}><FontAwesomeIcon icon={faSignOutAlt}/></div>
                                        </div>
                                    : <Button variant="cdh-general-blue-dbrown" disabled={loginDisabled} onClick={() => setShowLoginModal(true)}>{t('LOGIN')}</Button>
                                }
                            </div>
                            <div className={styles.step_button}>
                                <Button variant="cdh-general-blue-dbrown" disabled={bindDisabled} onClick={handleBindButtonClicked}>{t('LINK_WALLET_USER_ID')}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            { boxFooter(false) }
            <div className={styles.witch_image}><img src={witchImage} /></div>
        </div>
    );

    const renderRegistered = (
        <>
        <div className={styles.registered}>
            <div className={styles.header_text}>{t('BINDING_SUCCESS')}</div>
            <div className={styles.divider}><img src={bindBoxDividerShort} /></div>
            <div className={styles.registered_body}>
                <div><img src={cdhLogo} className={styles.game_image} /></div>
                <div className={styles.description}>
                    <div className={styles.title}>{t('GAME_ID')}</div>
                    <div className={styles.text}>{cdhUserId != null ? cdhUserId : <></>}</div>
                    <div>
                        {
                            cdhUserDetails != null
                            ?
                            <div className={styles.user_details_section}>
                                <div className={styles.user_details_header}>{t('BINDED_WITH_WALLET_ADDRESS').replace('{0}', selectedAccount != null ? middleEllipsis(selectedAccount) : "")}</div>
                                <div className={styles.user_details}>
                                    <div className={styles.user_detail_box}><img src={levelIcon} /> {t('LEVEL_PARAM').replace('{0}', cdhUserDetails.Avatar.AvatarLevel)} </div>
                                    <div className={styles.user_detail_box}><img src={gemIcon} /> {cdhUserDetails.Currencies.Gems}</div>
                                </div>
                            </div>
                            : <></>
                        }
                    </div>
                    <div className={styles.logout_button_container}><Button variant="cdh-general-white" className={styles.logout_button} onClick={signOut}>{t('LOGOUT')}</Button></div>
                </div>
            </div>
        </div>
        {/* <div className={styles.registered_added_text}>{t('CLAIMING_FEATURE_COMING_SOON')}</div> */}
        </>
    );

    return (
        <>
            <div className={styles.claim_tower_container} id='link'>
                <Container className={styles.section_container}>
                {
                    dateNow <= linkStartDate
                    ? renderComingSoon
                    : dateNow >= linkEndDate
                        ? renderBindPeriodEnded
                        : registered ? renderRegistered : renderNonRegistered
                }
                <div className={styles.displaynone} id="temp_firebase"><div id="firebaseui-auth-container"></div></div>
                </Container>
            </div>

            <Modal show={showLoginModal} className={styles.login_modal} centered onShow={HandleShowLoginModalOnShow} onHide={()=> setShowLoginModal(false)}>
                <div className={styles.close_btn} onClick={()=> setShowLoginModal(false)}><FontAwesomeIcon icon={faTimes}/></div>
                <Modal.Body>
                    <div className={styles.login_modal_body}>
                        <div className={styles.header_texts}>{t('LOGIN')}</div>
                        {
                            isInChina
                            ? <SignInWithApple content={{ callback:postProcessAppleSignedIn }} />
                            : <div id="firebaseui-auth-container2"></div>
                        }
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showBindModal} className={styles.bind_modal} centered onHide={()=> setShowBindModal(false)}>
                <div className={styles.close_btn} onClick={()=> setShowBindModal(false)}><FontAwesomeIcon icon={faTimes}/></div>
                <Modal.Body>
                    <div className={styles.header}>
                        <div className={styles.title}>{t('BIND_GAME_ID_TO_WALLET_CDH')}</div>
                    </div>
                    <div className={styles.bind_section}>
                        <div className={styles.bind_entity}>
                            <div className={styles.image_container}><img src={cdhLogo} className={styles.cdh_logo}/></div>
                            <div>
                                <div className={styles.entity_title}>{t('GAME_ID')}</div>
                                <div className={styles.entity_description}>{cdhUserId}</div>
                                {
                                    cdhUserDetails != null
                                    ? <div className={styles.bind_user_details}>
                                        <div className={styles.user_detail}><img src={levelIcon}/> {t('LEVEL_PARAM').replace('{0}', cdhUserDetails.Avatar.AvatarLevel)}</div>
                                        <div className={styles.user_detail}><img src={gemIcon}/> {cdhUserDetails.Currencies.Gems}</div>
                                    </div>
                                    : <></>
                                }
                            </div>
                        </div>
                        <div className={`${ !bindAgreed ? styles.bind_logo_disabled : ""} ${styles.bind_logo}`}>
                            <FontAwesomeIcon icon={faLink}/>
                        </div>
                        <div className={styles.bind_entity}>
                            <div className={styles.image_container}><img src={metamaskLogo} className={styles.metamask_logo}/></div>
                            <div className={styles.entity_title}>{t('WALLET_ADDRESS')}</div>
                            <div className={styles.entity_description}>
                                { web3WalletConnected
                                    ? <>
                                        <div>{middleEllipsis(selectedAccount)}</div>
                                        <div className={styles.copy_button} ref={copyTarget} onClick={()=> copyWalletAddress(selectedAccount)}><FontAwesomeIcon icon={faCopy}/></div>
                                        <Overlay target={copyTarget.current} placement="top" show={showCopied} rootClose={true} onHide={() => setShowCopied(false)}>
                                            <Tooltip>{t('Copied!')}</Tooltip>
                                        </Overlay>
                                    </>
                                    : <></>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={styles.footer}>
                        <div className={styles.checkbox}>
                            <input type="checkbox" id="bindAagreement" defaultChecked={bindAgreed} onChange={() => setBindAgreed(!bindAgreed)}/>
                        </div>
                        <label htmlFor="bindAagreement">
                            <div>{t('ONCE_THEY_ARE_BINDED')}</div>
                            <div>{t('PLEASE_MAKE_SURE_WALLET_AVAILABLE')}</div>
                        </label>
                    </div>
                    <div className={styles.footer_button}>
                        <Button variant="cdh-general-blue" className={styles.confirm_btn} disabled={!bindAgreed || bindingWallet} onClick={handleBindWallet}>{t('CONFIRM_BTN')}</Button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={fetchingUserData} className={styles.loading_modal} backdrop='static' centered>
                <Modal.Body>
                    <Image className='LoadingWheel' src={loadingWheel}/>
                    <div className={styles.loading_text}>{t('LOADING_SCREEN')}</div>
                </Modal.Body>
            </Modal>

            <Modal show={showBindErrorModal} className={styles.bind_error_modal} dialogClassName={styles.bind_error_modal_dialog} centered onHide={()=> setShowBindErrorModal(false)}>
                <div className={styles.back_btn} onClick={()=> setShowMobileLinkHelp(false)} style={{ display: showMobileLinkHelp ? 'block' : 'none' }}><FontAwesomeIcon icon={faArrowLeft}/></div>
                <div className={styles.close_btn} onClick={()=> setShowBindErrorModal(false)}><FontAwesomeIcon icon={faTimes}/></div>
                <Modal.Body className={styles.bind_error_body}>
                    <div className={styles.bind_error_texts} style={{ display: showMobileLinkHelp ? 'none' : '' }}>
                        <div><img src={errorImage} alt="error-character" /></div>
                        <div className={styles.bind_title}>{t('ERROR_MESSAGE_FAIL_TO_LINK_CDH')}</div>
                        <div className={styles.bind_subtitle}>{t('ERROR_MESSAGE_FAIL_TO_LINK_CONTEXT_CDH')}</div>
                        {/* <div className={styles.bind_error_code}>{bindErrorCode == bindErrorCodes.ERROR1 ? t('ERROR_WALLET_USED') : t('ERROR_USER_ID_USED')}</div> */}
                        <div className={styles.bind_wallet_details}>
                            <div style={{ color: "#AAAAAA" }}>{t('WALLET_CANNOT_BE_LINKED')}</div>
                            <div className={styles.wallet_address}>{selectedAccount}</div>
                        </div>
                        <Button variant="cdh-general-blue" className={styles.switch_wallet_btn} onClick={()=>setShowBindErrorModal(false)}>{t('SWITCH_WALLET')}</Button>
                    </div>
                    <div className={styles.horizontal_separator}></div>
                    <div className={styles.bind_footer}>
                        <div className={styles.help_section_title} style={{ display: showMobileLinkHelp ? 'block' : '' }}>{t('NEED_HELP')}</div>
                        <div className={styles.help_section_title_mobile} onClick={() => setShowMobileLinkHelp(true)} style={{ display: showMobileLinkHelp ? 'none' : '' }}>{t('NEED_HELP')}</div>

                        <div className={styles.help_section_points} style={{ display: showMobileLinkHelp ? 'flex' : '' }}>
                            <div className={styles.help_point}>
                                <div className={styles.help_point_title}>{t('IN_GAME_ASSIST')}</div>
                                <div className={styles.help_point_details}>
                                    {t('IN_GAME_ASSIST_DETAILS')}
                                </div>
                            </div>
                            <div className={styles.help_point}>
                                <div className={styles.help_point_title}>{t('JOIN_DISCORD')}</div>
                                <div className={styles.help_point_details}>
                                    {/* {t('JOIN_DISCORD_DETAILS')} */}
                                    <Trans i18nKey="JOIN_DISCORD_DETAILS" components={{ italic: <i />, bold: <strong /> }}></Trans>
                                </div>
                                <div className={styles.help_point_icon}>
                                    <a href="https://discord.gg/MzKppSCKVu" target="_blank" rel="noopener noreferrer">
                                        <img src={discordLogo} alt="discord" />
                                    </a>
                                </div>
                            </div>
                            <div className={styles.help_point}>
                                <div className={styles.help_point_title}>{t('SEND_EMAIL')}</div>
                                <div className={styles.help_point_details}>
                                    {t('SEND_EMAIL_DETAILS')}<br /><a href="mailto:cdh-support@animocabrands.com">cdh-support@animocabrands.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export { ClaimTower };
