import React, { useState, useEffect, useContext } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import CardItem from '../CardItem/CardItem';
import styles from './CardsListing.module.scss';
import iconNext from '../../assets/icons/arrow_next_page.svg';
import ReactPaginate from 'react-paginate';
import TransferCardModal from '../TrasnferCardModal/TransferCardModal';
import Loading from '../Loading/Loading';
import { useHistory } from 'react-router-dom';
import { estimatePriorityFee } from '../../services/contractService';
import { toBN } from 'web3-utils';
import { getCardsInOrder, fetchCardDetails, fetchCards } from '../../services/cardService';
import { useTranslation } from 'react-i18next';
import { Web3Context } from '../../web3';
import { PolygonNetworkInfo } from '../../services/networkServices';
import { TokenContext, TYPES } from '../Context';


const CARD_NFTS_ENABLED = process.env.REACT_APP_CARD_NFTS_ENABLED;

const CardsListing = ({ inventoryContract, web3, selectedAccount, fetchCardsCount }) => {
  const [cardDetails, setCardDetails] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const cardPerPage = 12;
  const pagesVisited = pageNumber * cardPerPage;
  const [transferModal, setTransferModal] = useState(false);
  const [selectedTransferCard, setSelectedTransferCard] = useState('');
  const [selectedCardImage, setSelectedCardImage] = useState('');
  const [orderedNFTList, setOrderedNFTList] = useState([]);
  const [sortLatestFirst, setSortLatestFirst] = useState('desc');
  const history = useHistory();
  const [t] = useTranslation();
  const { networkId, switchNetwork } = useContext(Web3Context);
  const [nftList, setNftList] = useState([]);
  const [, dispatch] = useContext(TokenContext);

  if (CARD_NFTS_ENABLED === "false") {
    history.push("/inventory/chests");
  }

  useEffect(() => {
    if (transferModal === false) {
      setSelectedTransferCard('');
    }
  }, [transferModal]);

  useEffect(() => {
    if(selectedTransferCard !== "") {
      let cardImage;
      Object.keys(cardDetails).forEach((card) => {
        if (cardDetails[card].id === selectedTransferCard) {
          cardImage = cardDetails[card].image;
        }
      });
      setSelectedCardImage(cardImage);
    }
  }, [selectedTransferCard])

  useEffect(() => {
    if (selectedAccount) {
      getCardsReversed();
    }
  }, [selectedAccount])

  const getCardsReversed = async () => {
    let ownedItems = [];
    ownedItems = await fetchCards(inventoryContract, selectedAccount);
    ownedItems = ownedItems.reverse();

    dispatch({
        type: TYPES.SET_TOKENS_OWNED,
        payload: ownedItems,
    });

    setNftList(ownedItems);
  }

  const onTransferTokenToUser = async (transferAddress, tokenId, transferAmount, data, setLoading, setTransfer, setTxHashLink, setSignTransfer, setShowMessageModal, setErrorMsg) => {
    if (transferAddress != "" && transferAmount != 0 && inventoryContract) {
      try {
        setLoading(true);
        setSignTransfer(true);

        let userAddress = selectedAccount;

        let gasPrice = await web3.eth.getGasPrice();
        let gasPriceBN = toBN(gasPrice);
        let gasEstimate = await inventoryContract.methods
          .safeTransferFrom(userAddress, transferAddress, tokenId, transferAmount, data)
          .estimateGas({from: selectedAccount});
        let finalGasPrice = gasPriceBN.add(toBN(parseInt(0.1 * gasPriceBN))).toString();
        let estimatedPriorityFee = await estimatePriorityFee(web3);
        let finalMaxFeePerGas = toBN(estimatedPriorityFee).add(toBN(parseInt(0.1 * gasPriceBN))).toString();

        let receipt = await inventoryContract.methods.safeTransferFrom(userAddress, transferAddress, tokenId, transferAmount, data).send({
          from: selectedAccount,
          gasLimit: gasEstimate,
          gasPrice: finalGasPrice,
          maxFeePerGas: finalMaxFeePerGas,
          maxPriorityFeePerGas: estimatedPriorityFee
        })
          .on('transactionHash', (txHash) => {
            console.log(`Transaction hash obtained: ${txHash}`);
            setTxHashLink(txHash);
          })
          .on('receipt', (receipt) => {
            console.log(receipt);
          })
          .on('error', err => {
            if (err.code !== 4001) {
              console.log(err);
              throw new Error("Token transfer failed");
            }
          });

        if (receipt.status === true) {
          setLoading(false);
          setTransfer(true);
          fetchCardsCount();
          getCardsReversed();
          return true;
        }
      } catch (e) {
          console.log(e);
          setLoading(false);
          setSignTransfer(false);
          setTransfer(false);

          if (e.code !== 4001) {
            setErrorMsg((prevState) => {
              return { ...prevState, transferError: 'Please provide valid wallet address' }
            });
            setShowMessageModal(true);
          }
      }
    } else {
      console.log("Please enter the correct arguments");
    }
  }

  const getCardDetails = async (nftList) => {
    setPageCount(Math.ceil(nftList?.length / cardPerPage));
    let paginatedList = nftList.slice(pagesVisited, (pageNumber + 1) * cardPerPage);
    let detailedCards = await fetchCardDetails(paginatedList);
    setCardDetails(detailedCards);
  }

  const updateCardsOrder = async (nftList, order) => {
    let cards = await getCardsInOrder(inventoryContract, nftList, order);
    setOrderedNFTList(cards);
  }

  useEffect(() => {
    if (nftList.length > 0) {
      updateCardsOrder(nftList, 'desc');
    }
  }, [nftList]);

  useEffect(() => {
    if (orderedNFTList.length > 0) {
      getCardDetails(orderedNFTList);
    }
  }, [orderedNFTList])

  useEffect(() => {
    if(orderedNFTList.length > 1) {
      getCardDetails(orderedNFTList);
    }
  }, [pageNumber]);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    setCardDetails([]);
  };

  const handleFilterChange = (e) => {
    let sortDirection = e.target.value;
    setSortLatestFirst(sortDirection);
    if (sortDirection === 'desc') {
      updateCardsOrder(nftList, 'desc');
    } else {
      updateCardsOrder(nftList, 'asc');
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.list_filtering}>
        <select name="filtering" id="filtering" value={sortLatestFirst} onChange={(e) => handleFilterChange(e)}>
          <option value="desc">{t('LATEST')}</option>
          <option value="asc">{t('OLDEST')}</option>
        </select>
      </div>

      {Object.keys(cardDetails).length > 0 ? (
        <Row className={`${styles.row} margin-escape`}>
        {
          Object.keys(cardDetails)?.map((card, index) => {
            return (
              <Col xs={6} md={4} lg={3} className={`mb-4 ${styles.col}`} key={index}>
                <CardItem itemDetails={cardDetails[card]} id={cardDetails[card].id} setTransferModal={setTransferModal} setSelectedTransferCard={setSelectedTransferCard} />
              </Col>
            );
          })
        }
      </Row>
      ) : (
        <div className='d-flex flex-column align-items-center'>
          {networkId !== parseInt(process.env.REACT_APP_POLYGON_CHAIN_ID) &&
            <div className={styles.network_message}>
              <span>{t('CONFIRM_AND_SWITCH_TO_POLYGON')}</span>
              <Button className={styles.switch_btn} onClick={() => switchNetwork(PolygonNetworkInfo)}>{t('SWITCH_BTN')}</Button>
            </div>
          }
          <Loading />
        </div>
      )}
      <div className={styles.paginationContainer}>
        <ReactPaginate
          previousLabel={null}
          nextLabel={<img src={iconNext} alt='nextIcon' />}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={styles.paginationBtns}
          previousClassName={styles.prevBtn}
          nextLinkClassName={styles.nextBtn}
          activeClassName={styles.activeBtn}
        />
        <div className={styles.pageInfo}>
          <button className={styles.currentPage}>{pageNumber + 1}</button>
          <span>of {pageCount} pages</span>
        </div>
      </div>
      {transferModal && (
          <TransferCardModal
              isCard={true}
              isChest={false}
              openModal={transferModal}
              setOpenModal={setTransferModal}
              onTransferTokenToUser={onTransferTokenToUser}
              web3={web3}
              selectedTransferCard={selectedTransferCard}
              itemImg={selectedCardImage}
          />
      )}
    </div>
  );
};

export default CardsListing;
